import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import {IDropdownSettings} from "../../../LibrerieCustom/MultiselectDropdown";

@Component({
  selector: 'app-categorie-nav-select',
  templateUrl: './categorie-nav-select.component.html',
  styleUrls: ['./categorie-nav-select.component.scss']
})
export class CategorieNavSelectComponent implements OnInit ,OnChanges{
    @Input() categorie:any[];
    @Input() nameCategoria1:string;
    @Input() nameCategoria2:string;
    @Input() nameCategoria3:string;
    @Input() cat_id : number;
    @Output() cat_idChange = new EventEmitter<number>();
    sottoCategoria:any;
    prestazione:any;
    selectedCategoria:any;
    selectedSottoCategoria:any;
    selectedPrestazione:any;
    dropSetting: IDropdownSettings= { singleSelection: true, singleOutput: true, idField: 'id', textField: 'categoria',  allowSearchFilter: true, }
    ngOnChanges(changes: SimpleChanges) {
        if(changes && changes['cat_id'] ) {
            this.selectedCategoria = this.elamiavolta(this.categorie)
            this.selectedSottoCategoria = this.elamiavolta(this.selectedCategoria.figli);
            this.selectedPrestazione = this.elamiavolta(this.selectedSottoCategoria.figli);
        }
      }

    ngOnInit(){
    }
    elamiavolta(cat){
        return cat.find(x => x.id == this.cat_id) ?? cat.find(x => x.figli.find(y=>y.id == this.cat_id)) ?? cat.find(z => z.figli.find(x => x.figli.find(y=>y.id == this.cat_id)))
    }

    categoriaChange(){
        this.selectedSottoCategoria = null;
        this.selectedPrestazione = null;
        if(this.selectedCategoria && this.selectedCategoria?.figli.length > 0)
            this.sottoCategoria = this.selectedCategoria.figli;
        else{
            this.cat_id = this.selectedCategoria?.id;
            this.cat_idChange.emit(this.cat_id);
        }


    }
    sottoCategoriaChange(){
        if(this.selectedSottoCategoria && this.selectedSottoCategoria?.figli.length > 0)
            this.prestazione = this.selectedSottoCategoria.figli;
        else{
            this.cat_id = this.selectedSottoCategoria?.id;
            this.cat_idChange.emit(this.cat_id);
        }
}

    prestazioneChange(){
        if(this.selectedPrestazione){
            this.cat_id = this.selectedPrestazione?.id;
            this.cat_idChange.emit(this.cat_id);
        }
    }



}
