<div class="page row">
  <div class="col-lg-3" *ngIf="environment.TaskAddon['sidebarFilter'] && openFilter"
       style="transition: 0.2s ease-in; background-color: white;">
    <div class="card">

      <div class="card-body">
        <h5 class="card-title">Filtri</h5>
        <div *ngIf="filtrife?.length > 1 && !hideFiltriFe && !hideFilter">
          <label for="tutti" class="control-label col-form-label"> Tipo </label>
          <ng-multiselect-dropdown required [settings]="dropdownSettings" [placeholder]="'Tipo'" id="tipo"
                                   [data]="filtrife"
                                   [(ngModel)]="filtrifeSel" (ngModelChange)="filtrifechng()">
          </ng-multiselect-dropdown>
        </div>
        <div *ngIf="filtrife?.length > 1 && subFilter?.length>0 && !hideFilter">
          <label for="tutti" class="control-label col-form-label"> Stato </label>
          <ng-multiselect-dropdown required [settings]="subdropdownSettings" [data]="subFilter"
                                   [(ngModel)]="subFilterSel" (ngModelChange)="subFiltrifechng()">
          </ng-multiselect-dropdown>
        </div>
        <div *ngIf="permission.hasOperativeRole('AZ') && !persona">
          <label for="tutti" class="control-label col-form-label"> Tutti </label>
          <input type="checkbox" [(ngModel)]="filtro.tutti" (ngModelChange)="Refresh()" class="form-control" id="tutti">
        </div>
        <app-field-viewer [(Campi)]="environment.TaskAddon['sidebarFilter']" [(Oggetto)]="filtro"
                          (OnChange)="Refresh()"></app-field-viewer>
      </div>
    </div>

  </div>
  <div [ngClass]="!environment.TaskAddon['sidebarFilter'] || !openFilter ? 'col-12': 'col-lg-9'">
    <div class="d-md-flex align-items-center bg-white p-2" *ngIf="environment.Modules.Scadenzario">
      <span class="d-flex align-items-center p-2 ">
        <h5 class="mb-0 mr-1 text-dark font-weight-bold font-14"></h5>
        <span class="badge badge-danger badge-pill px-3 font-weight-bold font-14">{{collectionSize}}</span>
      </span>
      <span href="javascript:void(0)" *ngIf="Aperte" class="d-flex align-items-center p-2 ">
        <h5 class="mb-0 mr-1 text-dark font-weight-bold font-14"> Scadute:</h5>
        <span class="badge badge-danger badge-pill px-3 font-weight-bold font-14">{{Aperte}}</span>
      </span>
      <div class="ml-auto mt-3 mt-md-0">
        <button type="button" *ngIf="hasAddScadenza" (click)="AggiungiScadenza()" class="btn btn-cyan rounded-pill p-2">
          <i
            class="fas fa-plus  font-14 mr-1 text-white"></i>
          <span class="ml-1 text-white font-weight-bold">Aggiungi Scadenza</span>
        </button>
      </div>
    </div>
    <div class="d-md-flex align-items-center bg-white p-2" *ngIf="hasbuttons()">
      <div class="ml-auto mt-3">
        <ng-container *ngFor="let btn of getAddButtons()">
          <div  class=" mt-3 mt-md-0" >
            <button type="button" (click)="addDynamicButton(btn)" class="btn btn-cyan rounded-pill p-2">
              <i class="fas fa-plus  font-14 mr-1 text-white"></i>
              <span class="ml-1 text-white font-weight-bold">Aggiungi {{ btn.Label }}</span>
            </button>
          </div>
        </ng-container>
      </div>
    </div>

    <div class="d-md-flex align-items-center bg-white p-2" *ngIf=" environment.Modules.Rma ">
      <div class="mt-3 mt-md-0">

        <div class="d-flex align-items-center ml-3 justify-content-start"
             *ngIf="environment.TaskAddon['sidebarFilter']">

          <button type="button" (click)="openFilter = !openFilter" class="btn p-2">
            <i class="fas fa-filter  mr-1 " style="font-size: 18px"
               [ngClass]="openFilter ? 'text-info': 'text-dark'"></i>
          </button>
          <span class="ml-1 text-dark"
                style="font-size: 25px; text-transform: uppercase; font-weight: 700;">{{title ?? ''}}</span>
        </div>

      </div>
      <div class="ml-auto mt-3 mt-md-0" *ngIf="environment.ClientiAddOn.rma  && environment.RmaAddOn['AddinCalendar']">
        <div class="d-flex align-items-center ml-3">
          <button type="button" (click)="AggiungiRma()" class="btn btn-cyan  p-2">
            <i class="fas fa-plus font-14 mr-1 text-white"> </i>
            <span class="ml-1 text-white font-weight-bold">{{'RMA.addTicket' | translate}}</span>
          </button>
        </div>

      </div>
    </div>
    <div class="card" *ngIf="filtri?.length > 0">

      <div class="card-body">
        <app-field-viewer [(Campi)]="filtrii" [(Oggetto)]="filtro"
        (OnChange)="Refresh()"></app-field-viewer>
      </div>
    </div>
    <div class="card" *ngIf=" !environment.TaskAddon['sidebarFilter'] && !filtri">

      <div class="card-body">
        <h5 class="card-title"><span class="ml-1 text-dark"
                                     style="font-size: 25px; text-transform: uppercase; font-weight: 700;">{{title ?? ''}}</span>
        </h5>
        <div class="row">

          <div class="col-lg-4" *ngIf="!hideFiltriFe && !hideFilter && filtrife?.length>0">
            <label for="tutti" class="control-label col-form-label"> Tipo </label>
            <ng-multiselect-dropdown required [settings]="dropdownSettings" [placeholder]="'Tipo'" id="tipo"
                                     [data]="filtrife"
                                     [(ngModel)]="filtrifeSel" (ngModelChange)="filtrifechng()">
            </ng-multiselect-dropdown>
          </div>
          <div class="col-lg-4" *ngIf="subFilter?.length>0 && !hideFilter">
            <label for="tutti" class="control-label col-form-label"> Stato </label>
            <ng-multiselect-dropdown required [settings]="subdropdownSettings" [data]="subFilter"
                                     [(ngModel)]="subFilterSel" (ngModelChange)="subFiltrifechng()">
            </ng-multiselect-dropdown>
          </div>
          <div class="col-lg-4"
               *ngIf="permission.hasOperativeRole('AZ') && !persona && !environment.TaskAddon['sidebarFilter']">
            <label for="tutti" class="control-label col-form-label"> Persone </label>
            <ng-multiselect-dropdown [settings]="userdropdownSettings" [placeholder]="'Persone'" id="tizi"
                                     [data]="Persone"
                                     [(ngModel)]="PersoneSelezionate" (ngModelChange)="Refresh()">
            </ng-multiselect-dropdown>
          </div>
          <div class="col-lg-1" *ngIf="permission.hasOperativeRole('AZ') && !persona">
            <label for="tutti" class="control-label col-form-label"> Tutti </label>
            <input type="checkbox" [(ngModel)]="filtro.tutti" (ngModelChange)="Refresh()" class="form-control"
                   id="tutti">
          </div>
          <div class="col-lg-12">
            <app-field-viewer [(Campi)]="environment.TaskAddon['Filter']" [(Oggetto)]="filtro"
                              (OnChange)="Refresh()"></app-field-viewer>
          </div>

        </div>
      </div>
    </div>
    <div class="d-md-flex align-items-center border-top bg-white p-3 " *ngIf="!filtri && !fromDashboard && !environment.TaskAddon['hideDateFilter']">
      <button type="button" (click)="GetScadute()" [ngClass]="scadute? 'btn btn-outline-info  p-2':'btn btn-dark  p-2'">
        <span class="ml-1  font-weight-bold">Scadute</span>
      </button>
      <button type="button" (click)="ModFilter(0)"
              [ngClass]="filtro.mese == 1? 'btn btn-outline-info  p-2':'btn btn-dark  p-2'">
        <span class="ml-1  font-weight-bold">Gennaio</span>
      </button>
      <button type="button" (click)="ModFilter(1)"
              [ngClass]="filtro.mese == 2? 'btn btn-outline-info  p-2':'btn btn-dark  p-2'">
        <span class="ml-1  font-weight-bold">Febbraio</span>
      </button>
      <button type="button" (click)="ModFilter(2)"
              [ngClass]="filtro.mese == 3? 'btn btn-outline-info  p-2':'btn btn-dark  p-2'">
        <span class="ml-1  font-weight-bold">Marzo</span>
      </button>
      <button type="button" (click)="ModFilter(3)"
              [ngClass]="filtro.mese == 4? 'btn btn-outline-info  p-2':'btn btn-dark  p-2'">
        <span class="ml-1  font-weight-bold">Aprile</span>
      </button>
      <button type="button" (click)="ModFilter(4)"
              [ngClass]="filtro.mese == 5? 'btn btn-outline-info  p-2':'btn btn-dark  p-2'">
        <span class="ml-1 font-weight-bold">Maggio</span>
      </button>
      <button type="button" (click)="ModFilter(5)"
              [ngClass]="filtro.mese == 6? 'btn btn-outline-info  p-2':'btn btn-dark  p-2'">
        <span class="ml-1  font-weight-bold">Giugno</span>
      </button>
      <button type="button" (click)="ModFilter(6)"
              [ngClass]="filtro.mese == 7? 'btn btn-outline-info  p-2':'btn btn-dark  p-2'">
        <span class="ml-1  font-weight-bold">Luglio</span>
      </button>
      <button type="button" (click)="ModFilter(7)"
              [ngClass]="filtro.mese == 8? 'btn btn-outline-info  p-2':'btn btn-dark  p-2'">
        <span class="ml-1  font-weight-bold">Agosto</span>
      </button>
      <button type="button" (click)="ModFilter(8)"
              [ngClass]="filtro.mese == 9? 'btn btn-outline-info  p-2':'btn btn-dark  p-2'">
        <span class="ml-1  font-weight-bold">Settembre</span>
      </button>
      <button type="button" (click)="ModFilter(9)"
              [ngClass]="filtro.mese == 10? 'btn btn-outline-info  p-2':'btn btn-dark  p-2'">
        <span class="ml-1 font-weight-bold">Ottobre</span>
      </button>
      <button type="button" (click)="ModFilter(10)"
              [ngClass]="filtro.mese == 11? 'btn btn-outline-info  p-2':'btn btn-dark  p-2'">
        <span class="ml-1 font-weight-bold">Novembre</span>
      </button>
      <button type="button" (click)="ModFilter(11)"
              [ngClass]="filtro.mese == 12? 'btn btn-outline-info  p-2':'btn btn-dark  p-2'">
        <span class="ml-1 font-weight-bold">Dicembre</span>
      </button>
      <button type="button" (click)="ModFilter(12)"
              [ngClass]="filtro.mese == 13? 'btn btn-outline-info  p-2':'btn btn-dark  p-2'">
        <span class="ml-1 font-weight-bold">Tutti</span>
      </button>
      <div class="col-lg-1 d-flex align-items-center">
        <label for="anno" class="control-label col-form-label"></label>
        <input type="number" step="1" min="1990" [(ngModel)]="filtro.anno" (ngModelChange)="ModFilter(filtro.mese - 1)"
               class="form-control" id="anno">
      </div>
    </div>
    <div class="table-responsive">
      <table class="table bg-white table-hover table-fixed align-middle mb-0 ">
        <thead class="fi">
        <tr *ngIf="!(dynamicHeader()?.length > 0) && View == 'Horizontal'">

          <th>Titolo</th>
          <th scope="col">data inizio</th>
          <th scope="col">data fine</th>
          <th scope="col">Azioni</th>
        </tr>
        <tr *ngIf="dynamicHeader()?.length > 0 && View == 'Horizontal'">
          <ng-container *ngFor="let campo of dynamicHeader()">
            <th scope="col bg-white"  (click)="order(campo)"
                *ngIf="permission.isntOperativeRoles(campo.prohibitedRoles) && permission.isOperativeRoles(campo.AllowedRoles) && headShow(campo)">{{campo.nome}}</th>
          </ng-container>
          <th scope="col">Azioni</th>
        </tr>
        <tr *ngIf="View == 'Vertical'">
          <th>&nbsp;</th>
          <th scope="col">Azioni</th>
        </tr>
        </thead>
        <tbody class="scrollable tablelist" *ngIf="View == 'Vertical'">
        <tr *ngFor="let item of scadenze " [ngbTooltip]="item.note">
          <td (click)='EditButton(item)'>
            <div class="row">
              <div class="col-12">
                <span class="font-16" style="font-weight: bold !important;">Titolo: &nbsp;</span>
                <span class="font-16 ">{{item.nome}}</span>
              </div>
              <div class="col-12">
                <span class="font-14 font-weight-bold">Caricata il: &nbsp;</span>
                <span class="font-14 ">{{item.data_inizio_prevista | date:'dd/MM/yyyy'}} alle ore {{item.data_inizio_prevista | date:'HH:mm'}}</span>
              </div>
              <div class="col-12">
                <span class="font-16 font-weight-bold" style="font-weight: bold !important;">Descrizione:</span>
              </div>
              <div class="col-12 " style="margin-left: 20px;">
                <span class="font-16 ">{{item.note}}</span>
              </div>
            </div>
          </td>
          <td class="align-middle">
            <a href="javascript:void(0)" *ngIf="!DisabledTask(item)" ngbTooltip="Modifica" class="link font-16 text-info font-medium mr-2"
               (click)='EditButton(item)'>
              <i-feather name="edit-3" class="feather-sm"></i-feather>
            </a>
            <a *ngIf="item.type == 'S'" href="javascript:void(0)" ngbTooltip="Elimina"
               class="link font-16 text-danger font-weight-bold"
               (click)='DeleteButton(item)'>
              <i-feather name="trash-2" class="feather-sm"></i-feather>
            </a>
          </td>
        </tr>
        </tbody>
        <tbody class="scrollable tablelist" *ngIf="!(dynamicHeader()?.length > 0) && View == 'Horizontal'">
        <tr *ngFor="let item of scadenze " [ngbTooltip]="item.note">
          <td (click)='EditButton(item)'>
            <span class="badge badge-pill px-3   font-14"
                  *ngIf="item.type == 'R' && environment.RmaAddOn['fieldProgressivo']">
              {{ (item.padre ? 'Intervento' : 'RMA.title') | translate }}
              {{ (" #" + (item[environment.RmaAddOn['fieldProgressivo']] ? item[environment.RmaAddOn['fieldProgressivo']]?.toString() : "") + " " + (item?.nome ?? ''))}}
            </span>

            <span class="badge badge-pill px-3   font-14"
                  *ngIf="!environment.RmaAddOn['fieldProgressivo']">
              {{getTaskTitle(item)}}
              <a *ngIf="Warning(item)?.visible" href="javascript:void(0)"
                 [ngbTooltip]="Warning(item)?.text"
                 class="link font-16 text-danger font-weight-bold">
              <i class="fas fa-exclamation-triangle text-warning"></i>
            </a>
            <a *ngIf="WarningNoResp(item)?.visible" href="javascript:void(0)"
               [ngbTooltip]="WarningNoResp(item)?.text"
               class="link font-16 text-danger font-weight-bold">
              <i class="fas fa-exclamation-circle text-warning"></i>
            </a>
            <a *ngIf="tag(item)?.visible" href="javascript:void(0)"
               [ngbTooltip]="tag(item)?.text"
               class="link font-16 text-danger font-weight-bold">
              <i class="fas fa-exclamation-circle text-info"></i>
            </a>
            </span>
          </td>
          <td (click)='EditButton(item)'>
        <span class="badge badge-pill px-3   font-14">
<!--              [ngStyle]="{'color': item.chiuso? '#00ff00': '#ff0000'}"> -->
          {{item.data_inizio_prevista | date: 'dd/MM/yyyy HH:mm'}}</span>
          </td>
          <td (click)='EditButton(item)'>
        <span class="badge badge-pill px-3   font-14">
<!--              [ngStyle]="{'color': item.chiuso? '#00ff00': '#ff0000'}"> -->
          {{item.data_fine_prevista | date: 'dd/MM/yyyy HH:mm'}}</span>
          </td>
          <!--      <td class="align-middle" (click)='EditButton(item)'>-->
          <!--            <span class="badge text-dark rounded-pill font-16 mr-1" [ngStyle]="{'background-color': item.chiuso? '#00ff00': '#ff0000'}">-->
          <!--             {{ item.chiuso ? 'Chiusa': 'Aperta' }}-->
          <!--            </span>-->
          <!--      </td>-->
          <td class="align-middle" >
            <a href="javascript:void(0)" *ngIf="!DisabledTask(item)" ngbTooltip="Modifica" class="link font-16 text-info font-medium mr-2"
               (click)='EditButton(item)'>
              <i-feather name="edit-3" class="feather-sm"></i-feather>
            </a>
            <a *ngIf="item.type == 'S'" href="javascript:void(0)" ngbTooltip="Elimina"
               class="link font-16 text-danger font-weight-bold"
               (click)='DeleteButton(item)'>
              <i-feather name="trash-2" class="feather-sm"></i-feather>
            </a>
          </td>
        </tr>
        </tbody>
        <tbody *ngIf="dynamicHeader()?.length > 0 && View == 'Horizontal'">
        <tr *ngFor="let item of scadenze " [ngbTooltip]="this.TaskTooltip(item)">
          <ng-container *ngFor="let campo of dynamicHeader()">
            <td
              *ngIf="permission.isntOperativeRoles(campo.prohibitedRoles) && permission.isOperativeRoles(campo.AllowedRoles) && headShow(campo)"
              (click)='RowClick(item)'>
          <span class="font-14" [ngClass]="{'badge badge-pill px-3': campoUtility.Colore(campo,item) }"
                [ngStyle]="{'color': campoUtility.Colore(campo,item)?  LightenColor(campoUtility.Colore(campo,item)) : '', 'background-color': campoUtility.Colore(campo,item)}">
            <ng-container *ngFor="let oggetto of campo.oggetti; let i = index">
              <ng-template [ngIf]="campo.type!= 'object' && campo.inputType == 'date' && item[oggetto]">
                {{campoUtility.getObj(item, oggetto) | date: 'dd/MM/yyyy'}} &nbsp;
              </ng-template>
              <ng-template
                [ngIf]="campo.type == 'object' && campo.inputType == 'date' && campoUtility.getObj(item, oggetto) ">
                {{campoUtility.getObj(item, oggetto)[campo.field[i]] | date: 'dd/MM/yyyy'}} &nbsp;
              </ng-template>
              <ng-template [ngIf]="campo.type!= 'object' && campo.inputType == 'datetime-local' && item[oggetto]">
                {{campoUtility.getObj(item, oggetto) | date: 'dd/MM/yyyy HH:mm'}} &nbsp;
              </ng-template>
              <ng-template
                [ngIf]="campo.type == 'object' && campo.inputType == 'datetime-local' && campoUtility.getObj(item, oggetto)">
                {{campoUtility.getObj(item, oggetto)[campo.field[i]] | date: 'dd/MM/yyyy HH:mm'}} &nbsp;
              </ng-template>
               <ng-template
                 [ngIf]="campo.type!= 'object' && campo.inputType != 'date' && campo.inputType != 'datetime-local' && campo.type != 'replace' && campo.type != 'objArray' && campo.inputType != 'singleDropdown'">
                {{campoUtility.getObj(item, oggetto)}} &nbsp;
              </ng-template>
              <ng-template
                [ngIf]="campo.type == 'object' && campo.inputType != 'date' && campo.inputType != 'datetime-local' && item[oggetto] && campoUtility.getObj(item, oggetto)">
                {{campoUtility.getObj(item, oggetto)[campo.field[i]]}} &nbsp;
              </ng-template>
              <ng-template
                [ngIf]="campo.type == 'object' && campo.inputType != 'date' && campo.inputType != 'datetime-local' && !item[oggetto] && campo.oggettisec && campo.oggettisec[i] && item[campo.oggettisec[i]]">
                {{item[campo.oggettisec[i]][campo.field[i]]}} &nbsp;
              </ng-template>
              <ng-template [ngIf]="campo.type == 'replace' ">
                {{campoUtility.Replace(campo, item, oggetto)}} &nbsp;
              </ng-template>
              <ng-template [ngIf]="campo.type == 'objArray' ">
                <ng-container *ngFor="let obg of campoUtility.getObj(item, oggetto)">
                  {{obg[campo.field[i]]}}&nbsp;
                </ng-container>
              </ng-template>

           </ng-container>
          </span>
            </td>
          </ng-container>


          <!--      <td (click)='EditButton(item)'>-->
          <!--        <span class="badge badge-pill px-3   font-14">-->
          <!--&lt;!&ndash;              [ngStyle]="{'color': item.chiuso? '#00ff00': '#ff0000'}"> &ndash;&gt;-->
          <!--          {{ item.type == 'R' ? ("Ticket # " + (item?.id ?  item?.id?.toString() : "") + " " + item?.nome ?? '') : item.nome}}</span>-->
          <!--      </td>-->
          <!--      <td (click)='EditButton(item)'>-->
          <!--        <span class="badge badge-pill px-3   font-14">-->
          <!--&lt;!&ndash;              [ngStyle]="{'color': item.chiuso? '#00ff00': '#ff0000'}"> &ndash;&gt;-->
          <!--          {{item.data_inizio_prevista | date: 'dd/MM/yyyy HH:mm'}}</span>-->
          <!--      </td>-->
          <!--      <td (click)='EditButton(item)'>-->
          <!--        <span class="badge badge-pill px-3   font-14">-->
          <!--&lt;!&ndash;              [ngStyle]="{'color': item.chiuso? '#00ff00': '#ff0000'}"> &ndash;&gt;-->
          <!--          {{item.data_fine_prevista | date: 'dd/MM/yyyy HH:mm'}}</span>-->
          <!--      </td>-->
          <!--      <td class="align-middle" (click)='EditButton(item)'>-->
          <!--            <span class="badge text-dark rounded-pill font-16 mr-1" [ngStyle]="{'background-color': item.chiuso? '#00ff00': '#ff0000'}">-->
          <!--             {{ item.chiuso ? 'Chiusa': 'Aperta' }}-->
          <!--            </span>-->
          <!--      </td>-->
          <td class="align-middle">
            <a href="javascript:void(0)" *ngIf=" !DisabledTask(item) && (item.type !== 'L' || ( !(item.multimediaObject?.length == 1) || permission.isPermitted('gestione-formazione')))" [ngbTooltip]="item.type !='L' || permission.isPermitted('gestione-formazione')? 'Modifica' : 'Apri'" class="link font-16 text-info font-medium mr-2"
               (click)='EditButton(item)'>
              <i-feather name="edit-3" *ngIf="item.type !='L' || permission.isPermitted('gestione-formazione')" class="feather-sm"></i-feather>
              <i-feather name="eye" *ngIf="item.type == 'L' && item.multimediaObject?.length > 1 && !permission.isPermitted('gestione-formazione') && !DisabledTask(item)" class="feather-sm"></i-feather>
             </a>
            <a href="javascript:void(0)" *ngIf="item.type == 'L' && item.multimediaObject?.length == 1 && !permission.isPermitted('gestione-formazione') && !DisabledTask(item)" [ngbTooltip]="item.type !='L' || permission.isPermitted('gestione-formazione')? 'Modifica' : 'Apri'" class="link font-16 text-info font-medium mr-2"
               (click)='Segui(item)'>
              <span name="eye"  class=""> SEGUI </span>
            </a>
            <a *ngIf="item.type == 'S'" href="javascript:void(0)" ngbTooltip="Elimina"
               class="link font-16 text-danger font-weight-bold"
               (click)='DeleteButton(item)'>
              <i-feather name="trash-2" class="feather-sm"></i-feather>
            </a>
          </td>
        </tr>
        </tbody>
      </table>
    </div>
    <br>
    <div class="card" *ngIf="environment.legenda?.length>0">
      <div class="card-body">
        <div class="row">
          <ng-container *ngFor="let legenda of environment.legenda">
            <div class="col-4" *ngIf="legenda['list']">
              <h4 class="card-title">{{legenda.area | translate}}</h4>
              <div *ngFor="let item of legenda.legenda">
                <span style="height: 10px;width: 10px;background-color: #bbb;border-radius: 50%;display: inline-block;"
                      [ngStyle]="{'background-color': item.color}"></span>
                &nbsp;
                <span class="text-dark">{{item.label | translate}}</span>
                <br>
              </div>
            </div>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
</div>
