<div class="row">
  <div *ngIf="HasAdd" class="col-lg-3">
    <label for="user" class="control-label col-form-label" >Iscritti:</label>
    <ng-multiselect-dropdown [settings]="UserDropdownSettings" [placeholder]="'Iscritti'" id="user" name="categorie" [data]="Persone"
                             [(ngModel)]="personeSelezionate" (click)="filtroPersone('', true)" (onFilterChange)="filtroPersone($event,false)">
    </ng-multiselect-dropdown>
  </div>
  <div *ngIf="HasAdd" class="col-lg-3">
    <label for="user" class="control-label col-form-label" >Azienda:</label>
    <ng-multiselect-dropdown [settings]="AziendeDropdownSettings" [placeholder]="'Azienda'" name="categorie" [data]="Aziende"
                             [(ngModel)]="aziendeSelected" (ngModelChange)="SelectAzienda()">
    </ng-multiselect-dropdown>
  </div>
  <div *ngIf="HasAdd && aziendeSelected?.length > 0 && environment.ClientiAddOn['repartoReferente']" class="col-lg-3" >
    <label for="user" class="control-label col-form-label" >Reparto:</label>
    <ng-multiselect-dropdown [settings]="DropdownSettings" [placeholder]="'Reparto'" name="categorie" [data]="Reparti"
                             [(ngModel)]="repartiSelected" (ngModelChange)="SelectReparto()">
    </ng-multiselect-dropdown>
  </div>
  <div *ngIf="HasAdd  && aziendeSelected?.length > 0" class="col-lg-3">
    <label for="user" class="control-label col-form-label" >Dipendenti:</label>
    <ng-multiselect-dropdown [settings]="UserDropdownSettings" name="categorie" [data]="Dipendenti"
                             [(ngModel)]="dipendentiSelected" >
    </ng-multiselect-dropdown>
  </div>
  <div class="ml-auto mt-3 mt-md-0 d-flex align-items-center" *ngIf="HasAdd">
    <button class="btn btn-outline-info" style="width: 100%;" (click)="AddUser()" type="button">
      <i class="fas fa-plus-circle">&nbsp;Aggiungi</i>
    </button>
    <button class="btn btn-outline-info " style="width: 100%;" (click)="AddNewUser()" type="button">
      <i class="fas fa-user-circle">  Nuovo</i>
    </button>
  </div>
</div>
<br>
<div class="table-responsive" *ngIf="Alunni && !MediaCourse">
  <table class="table bg-white table-hover align-middle mb-0 ">
    <thead>
    <tr>
      <th>Nome</th>
      <th scope="col">Stato</th>
      <th scope="col">Email</th>
      <th scope="col" *ngIf="Modifica">Azioni</th>
    </tr>
    </thead>
    <tbody>
    <tr *ngFor="let item of Alunni">
      <td class="align-middle" >
        <span>
          <span class="badge badge-light text-dark rounded-pill font-16 mr-1">
            <i class="fas fa-user-circle"></i>
          </span>
           <span *ngIf="item.fullName"> {{item.fullName}}</span>
        </span>
      </td>

      <td class="align-middle" *ngIf="!GetAvanzamento(item).started"  >

          <span class="font-14" [ngClass]="{'badge badge-pill px-3': true }"
                [ngStyle]="{ 'background-color': 'gray', 'color': 'white'}">
            Da iniziare
          </span>
      </td>
      <td class="align-middle" *ngIf="GetAvanzamento(item).started && !GetAvanzamento(item).finished"  >

          <span class="font-14" [ngClass]="{'badge badge-pill px-3': true }"
                [ngStyle]="{ 'background-color': 'yellow', 'color': 'black'}">
            Da completare
          </span>
      </td>
      <td class="align-middle" *ngIf="GetAvanzamento(item).started && GetAvanzamento(item).finished"  >

          <span class="font-14" [ngClass]="{'badge badge-pill px-3': true }"
                [ngStyle]="{ 'background-color': 'green', 'color': 'black'}">
            Completato
          </span>
      </td>

      <td class="align-middle" >
        <span>
          <span class="badge badge-light text-dark rounded-pill font-16 mr-1">
            <i class="fas fa-address-card"></i>
          </span>
           <span *ngIf="item.email"> {{item.email}}</span>
        </span>
      </td>

      <td class="align-middle" *ngIf="Modifica">
        <a href="javascript:void(0)" *ngIf="Modifica" class="link font-16 text-info font-medium mr-2" (click)='editContact(item)'>
          <i-feather name="edit-3" class="feather-sm"></i-feather>
        </a>
        <a href="javascript:void(0)" *ngIf="Modifica" ngbTooltip="Elimina" class="link font-16 text-danger font-weight-bold"
           (click)='deleteContact(item)'>
          <i-feather name="trash-2" class="feather-sm"></i-feather>
        </a>
      </td>
    </tr>
    </tbody>
  </table>
</div>
<div class="table-responsive" *ngIf="Alunni && MediaCourse">
  <table class="table bg-white table-hover align-middle mb-0 ">
    <thead>
    <tr>
      <th>Nome</th>
      <th scope="col">Stato</th>
      <th scope="col">Avanzamento</th>
      <th scope="col" *ngIf="Modifica">Azioni</th>
    </tr>
    </thead>
    <tbody >
    <tr *ngFor="let item of Alunni">
      <td class="align-middle" >
        <span>
          <span class="badge badge-light text-dark rounded-pill font-16 mr-1">
            <i class="fas fa-user-circle"></i>
          </span>
           <span *ngIf="item.fullName"> {{item.fullName}}</span>
        </span>
      </td>
      <td class="align-middle" >
        <span>
          <span class="badge badge-light text-dark rounded-pill font-16 mr-1">
            <i class="fas fa-user"></i>
          </span>
           <span *ngIf="item.stato"> {{item.stato}}</span>
        </span>
      </td>
      <td class="align-middle" >
        <span>
          <span class="badge badge-light text-dark rounded-pill font-16 mr-1">
            <i class="fas fa-address-book"></i>
          </span>
           <span *ngIf="item.avanzamento"> {{item.avanzamento}}</span>
        </span>
      </td>
      <td class="align-middle" *ngIf="Modifica">
        <a href="javascript:void(0)" *ngIf="Modifica" class="link font-16 text-info font-medium mr-2" (click)='editContact(item)'>
          <i-feather name="edit-3" class="feather-sm"></i-feather>
        </a>
        <a href="javascript:void(0)" *ngIf="Modifica" ngbTooltip="Elimina" class="link font-16 text-danger font-weight-bold"
           (click)='deleteContact(item)'>
          <i-feather name="trash-2" class="feather-sm"></i-feather>
        </a>
      </td>
    </tr>
    </tbody>
  </table>
</div>
