import { Component, EventEmitter,Input, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-ripetizione-task',
  templateUrl: './ripetizione-task.component.html',
  styleUrls: ['./ripetizione-task.component.scss']
})
export class RipetizioneTaskComponent {
  isDropdownOpen = false;
  showOrder = false;
  @Input() ripetizione = {
    repeatEvery : 1,
    frequency : 'week',
    ordine:null,
    daysOfWeek : {
      monday: false,
      tuesday: false,
      wednesday: false,
      thursday: false,
      friday: false,
      saturday: false,
      sunday: false,
    },
    endCondition : 'never',
    endDate :  '',
    occurrences : 1,
  };
  @Output() ripetizioneChange = new EventEmitter<any>();
   


  toggleDropdown() {
    this.isDropdownOpen = !this.isDropdownOpen;
  }

  closeDropdown() {
    this.isDropdownOpen = false;
  }

  toggleDay(day: string) {
    this.ripetizione.daysOfWeek = this.ripetizione.daysOfWeek ?? { 
      monday: false,
      tuesday: false,
      wednesday: false,
      thursday: false,
      friday: false,
      saturday: false,
      sunday: false,
    };
    this.ripetizione.daysOfWeek[day] = !this.ripetizione.daysOfWeek[day];
    //this.ripetizioneChange.emit(this.ripetizione);
  }
  stampa(){
    console.log("Ripeti ", this.ripetizione.repeatEvery);
    console.log("Frequenza ", this.ripetizione.frequency);
    console.log("Giorni Selezionati ", this.ripetizione.daysOfWeek);
    console.log("Condizione Finale", this.ripetizione.endCondition);
    console.log("Data di Fine", this.ripetizione.endDate);
    console.log("Occorrenze", this.ripetizione.occurrences);
  }

  verify( name:string, min:number= 1){
    setTimeout(()=>{ this.ripetizione[name] =  !this.ripetizione[name] || this.ripetizione[name] < min ? min : this.ripetizione[name];}, 1000)
    
  }
}

  

