<div class="d-md-flex align-items-center justify-content-between bg-white p-2">
    <span class="d-flex align-items-center p-2 ">
    <h5 class="mb-0 mr-1 text-dark font-weight-bold font-14"> Totale:</h5>
    <span class="badge badge-danger badge-pill px-3 font-weight-bold font-14">{{Lista?.length}}</span>
    </span>
  <div class="ml-auto mt-3 mt-md-0" style="width: 25%" *ngIf="!lezione">
<!--    <label class="control-label col-form-label">Tipo</label>-->
    <ng-multiselect-dropdown  [settings]="classicTypeDropdownSettings" [placeholder]="'Selezionare il tipo'" [data]="Types" [(ngModel)]="SelectedTypes" (ngModelChange)="refreshTable(1)"></ng-multiselect-dropdown>
  </div>
  <div class="ml-auto mt-3 mt-md-0" style="width: 25%" *ngIf="CorsiAggiungibili && permission.isPermitted('gestione-formazione')">
    <ng-multiselect-dropdown style="width: 25%" [settings]="DropdownSettings" [placeholder]="'Selezionare il contenuto da aggiungere'" [data]="CorsiAggiungibili" [(ngModel)]="SelectedCourse" ></ng-multiselect-dropdown>
  </div>
  <div class="ml-auto mt-3 mt-md-0"  >
    <button type="button" (click)="add()" *ngIf="permission.isPermitted('gestione-formazione') && (!lezione || SelectedCourse?.length>0)" class="btn btn-cyan rounded-pill p-2" ><i
      class="fas fa-plus  font-14 mr-1 text-white"></i>
      <span class="ml-1 text-white font-weight-bold">Aggiungi</span>
    </button>
  </div>
</div>
<div class="table-responsive">
  <table class="table bg-white table-hover align-middle mb-0 no-wrap">
    <thead>
    <tr>
      <th >Nome</th>
      <th scope="col">Descrizione</th>
      <th scope="col">Tipo</th>
      <th *ngIf="!HideSoglia && permission.isPermitted('gestione-formazione')" scope="col">Soglia</th>
      <th scope="col">Azioni</th>
    </tr>
    </thead>
    <tbody>
    <tr *ngFor="let item of Lista | slice: (page-1) * pageSize : (page-1) * pageSize + pageSize">
      <td (click)='open(item)'>
        <!-- <img [src]='co.Imagepath' class="rounded-circle mr-2" alt="" height="45" width="45"> -->
        <span class="text-dark font-medium font-16">
            <span class="badge badge-light text-dark rounded-pill font-16 mr-1">
              <i-feather name="info" class="feather-sm"></i-feather>
            </span>
          {{ item.nome}}
          </span>
      </td>
      <td class="align-middle" (click)='open(item)'>
          <span>
            <span class="badge badge-light text-dark rounded-pill font-16 mr-1">
              <i class="fas fa-barcode"></i>
            </span>
            {{ item.descrizione}}
          </span>
      </td>
      <td class="align-middle " (click)='open(item)'>
          <span>
            <span class="badge badge-light text-dark rounded-pill font-16 mr-1">
              <i class="fas fa-barcode"></i>
            </span>
            {{ item.tipoObject.label}}
          </span>
      </td>
      <td class="align-middle " *ngIf="!HideSoglia && permission.isPermitted('gestione-formazione')">
        <input type="number" min="0.01"  [(ngModel)]="item.soglia" class="form-control">
      </td>
      <td class="align-middle" >
        <a href="javascript:void(0)" class="link font-16 text-success font-medium mr-2" (click)='open(item)'>
          <i class="fas fa-eye"></i>
        </a>
        <a *ngIf="permission.isPermitted('gestione-formazione') && Editabile" href="javascript:void(0)" class="link font-16 text-info font-medium mr-2" (click)='edit(item)'>
          <i class="fas fa-pencil-alt"></i>
        </a>
        <a *ngIf="permission.isPermitted('gestione-formazione') " href="javascript:void(0)" class="link font-16 text-info font-medium mr-2" (click)='delete(item)'>
          <i class="fas fa-trash-alt"></i>
        </a>
        <a href="javascript:void(0)"  *ngIf="item.allegato && item.allegato !== '' && permission.isPermitted('gestione-formazione')  && Editabile" class="link font-16  font-medium mr-2" (click)='download(item)'>
          <i-feather name="download" class="feather-sm"></i-feather>
        </a>
<!--        <a href="javascript:void(0)" class="link text-danger font-16 font-weight-bold" (click)='delete(item)'>-->
<!--          <i-feather name="trash-2" class="feather-sm"></i-feather>-->
<!--        </a>-->
      </td>
    </tr>
    </tbody>
  </table>
</div>
<div class="d-flex justify-content-center p-2 pt-3 bg-white border-top">
  <ngb-pagination (pageChange)="getPage($event)" id="paginationComponent" [maxSize]="10" [collectionSize]="Lista?.length ?? 0" [pageSize]="pageSize"></ngb-pagination>
</div>
