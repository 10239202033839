import { Routes } from '@angular/router';
import { FullComponent } from './layouts/full/full.component';
import {
  ActivateAcquisti,
  ActivateAdmin,
  ActivateCamera, ActivateDistinta,
  ActivateEcommerce,
  ActivateFormazione,
  ActivateInterventi, ActivateLavorazioni,
  ActivateListini,
  ActivatePrimaNota,
  ActivateProgetti,
  ActivateRma,
  ActivateScadenzario,
  ActivateSettings,
  ActivateShared,
  ActivateSpedizioni,
  ActivateStampe,
  ActivateTask,
  ActivateTicket, ActivateValutazioni, ActivateVerifica,
  AuthGuard
} from './Login/_guards/AuthGuard';
import {environment} from "../environments/environment";
import { SiteLayoutComponent } from './SiteLayout/site-layout.component';
import {SerialiModule} from "./Seriali/seriali.module";


export function  homepage(user): string {

  if(!user)
    return environment['unloggedHref'] ??  environment.href;
  if (user?.two_factor && environment.Modules.twoFactorAuth)
    return "/Login/verify";
  if (user?.cambio_pwd && environment.Modules['ForceReset'])
    return "/Login/changepassword";
  var ruoli = user?.ruoliObject;
  var ruoliAccesso = user?.ruoliAccessoObject;
  var homepage = environment.homepages.find(x=> x.Ruoli?.find(y=> ruoli && ruoli[0]?.id == y) != undefined || x['RuoliAccesso']?.find(z=> ruoliAccesso && ruoliAccesso[0]?.id == z) != undefined);
  return homepage?.link ?? environment.baseUrl
}


export function Approutes() {

  var href = homepage(JSON.parse(localStorage.getItem('currentUser')))
  return [
    {
      path: 'ecommerce',
      component: SiteLayoutComponent,
      children: [
        {
          path: '',
          loadChildren: ()=> import('./ecommerce/ecom.module'). then(m => m.EcomModule)
        },
        {
          path: 'assistenza',
          loadChildren: () => import('./rma/rma.module').then(m => m.RMAModule),
        }
      ]
    },
    {
      path: '',
      component: FullComponent,
      children: [
        { path: '', redirectTo: href, pathMatch: 'full' },
        {
          path: 'listautenti',
          loadChildren: () => import('./Admin/Admin.module').then(m => m.AdminModule),
          canActivate: [ActivateAdmin]
        },{
          path: 'task',
          loadChildren: () => import('./task/task.module').then(m => m.TaskModule),
          canActivate: [ActivateTask]
        },{
          path: 'dashboard',
          loadChildren: () => import('./shared/Shared.module').then(m => m.SharedModule),
          canActivate: [ActivateShared]
        },{
          path: 'progetti',
          loadChildren: () => import('./Progetti/Progetti.module').then(m => m.ProgettiModule),
          canActivate: [ActivateProgetti ]
        }, {
          path: 'stampe',
          loadChildren: () => import('./Stampe/Stampe.module').then(m => m.StampeModule),
          canActivate: [ActivateStampe]
        }, {
          path: 'interventi',
          loadChildren: () => import('./Interventi/Interventi.module').then(m => m.InterventiModule),
          canActivate: [ActivateInterventi]
        }, {
          path: 'scadenzario',
          loadChildren: () => import('./Scadenzario/Scadenzario.module').then(m => m.ScadenzarioModule),
          canActivate: [ActivateScadenzario]
        }, {
          path: 'primanota',
          loadChildren: () => import('./Prima_Nota/Prima_Nota.module').then(m => m.Prima_NotaModule),
          canActivate: [ActivatePrimaNota]
        }, {
          path: 'spedizioni',
          loadChildren: () => import('./Spedizioni/Spedizioni.module').then(m => m.SpedizioniModule),
          canActivate: [ActivateSpedizioni]
        }, {
          path: 'settings',
          loadChildren: () => import('./settings/settings.module').then(m => m.SettingsModule),
          canActivate: [ActivateSettings]
        }, {
          path: 'formazione',
          loadChildren: () => import('./formazione/formazione.module').then(m => m.FormazioneModule),
          canActivate: [ActivateFormazione]
        }, {
          path: 'bbb',
          loadChildren: () => import('./big-blue-button/big-blue-button.module').then(m => m.BigBlueButtonModule),
          canActivate: [AuthGuard]
        }, {
          path: 'ticket',
          loadChildren: () => import('./ticket/ticket.module').then(m => m.TicketModule),
          canActivate: [ActivateTicket]
        },
        {
          path: 'ecom',
          loadChildren: () => import('./ecommerce/ecom.module').then(m => m.EcomModule),
          canActivate: [ActivateEcommerce]
        },{
          path: 'rma',
          loadChildren: () => import('./rma/rma.module').then(m => m.RMAModule),
          canActivate: [ActivateRma]
        },{
          path: 'Cliente',
          loadChildren: () => import('./Admin/Admin.module').then(m => m.AdminModule),
          canActivate: [ActivateAdmin]
        },{
          path: 'listino',
          loadChildren: () => import('./listino/listino.module').then(m => m.ListinoModule),
          canActivate: [ActivateListini]
        },
        {
          path:'acquisti',
          loadChildren: () => import('./acquisti/acquisti.module').then(m => m.AcquistiModule),
          canActivate: [ActivateAcquisti]
        },
        {
          path:'valutazioni',
          loadChildren: () => import('./Valutazioni/Valutazioni.module').then(m => m.ValutazioniModule),
          canActivate: [ActivateValutazioni]
        },
        {
          path:'eventup',
          loadChildren: () => import('./event-up/event-up.module').then(m => m.EventUpModule),
        },
        {
          path:'blocchi',
          loadChildren: () => import('./blocchi/blocchi.module').then(m => m.BlocchiModule),
          canActivate: [ActivateProgetti]
        },
        {
          path:'verifica',
          loadChildren: () => import('./verifica/verifica.module').then(m => m.VerificaModule),
          canActivate: [ActivateVerifica]
        },
        {
          path: 'materiali',
          loadChildren: () => import('./Materiali/materiali.module').then(m => m.MaterialiModule),
          canActivate: [AuthGuard]
        },
        {
          path: 'magazzino',
          loadChildren: () => import('./Magazzino/magazzino.module').then(m => m.MagazzinoModule),
          canActivate: [AuthGuard]
        },
        {
          path: 'distinta',
          loadChildren: () => import('./distinta-base/distinta-base.module').then(m => m.DistintaBaseModule),
          canActivate: [ActivateDistinta]
        },
        {
          path: 'lavorazioni',
          loadChildren: () => import('./lavorazioni/lavorazioni.module').then(m => m.LavorazioniModule),
          canActivate: [ActivateLavorazioni]
        },
        {
          path: 'seriali',
          loadChildren: () => import('./Seriali/seriali.module').then(m => m.SerialiModule),
          canActivate: [AuthGuard]
        },
      ]

    },
    {
      path: 'Login',
      loadChildren: () => import('./Login/Login.module').then(m => m.LoginModule)
    }, {
      path: 'prodotti/login',
      loadChildren: () => import('./Login/Login.module').then(m => m.LoginModule)
    },{
      path: 'confirmation',
      loadChildren: () => import('./confirmation/confirmation.module').then(m => m.ConfirmationModule),
    },
    {
      path:'console',
      loadChildren: () => import('./camera/camera.module').then(m => m.CameraModule),
      canActivate: [ActivateCamera]
    },
    {
      path:'event-up',
      loadChildren: () => import('./event-up/event-up.module').then(m => m.EventUpModule),
    },
    {
      path: '**',
      //redirectTo: "/Login"
      redirectTo: href
    }
  ]
}


