import { RitornoPaginazione } from './../../shared/models/RitornoPaginazione';
import { ClassicType } from './../../shared/models/ClassicType';
import { map } from 'rxjs/operators';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiPathsService } from 'src/app/api-paths.service';
import { AuthenticationService } from 'src/app/Login/_services/authentication.service';
import {Task} from "../models/Task";
import {Filtri} from "../models/filtri";
import {Ticket} from "../../ticket/Models/ticket";
import {InterventiTecnico} from "../../Interventi/Models/InterventiTecnico";
import {Intervento} from "../../Interventi/Models/Intervento";
import {ProvaAcquisto} from "../../rma/_Models/prova-acquisto";
import {RmaData} from "../../rma/RmaData";

@Injectable({
  providedIn: 'root'
})
export class ApiRestService {
constructor(
    private http: HttpClient,
    private authenticationService: AuthenticationService,
    private apiPaths: ApiPathsService,
    private rmaData: RmaData,
  ) { }
  /**
   * Opzioni di default per le richieste HTTP
   */
  options() {
    return {
      headers: this.headers()
    };
  }
  headers() {
    return new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${this.authenticationService.currentUserValue.access_token}`,
      })
  }
  GetCalendarData(filter : Filtri, Calendar?){
    console.log(filter);
    const baseUrl = filter.rma? this.rmaData.getRmaLink() : this.apiPaths.getBaseAPI();
    const options = filter.rma? {headers : this.rmaData.getRmaHeaders()} :  this.options();

    return this.http.post<RitornoPaginazione<Task[]>>(
      `${baseUrl+ (Calendar? '/task/task/calendar' : '/task/task/list')}`,
      JSON.stringify(filter),
      options).pipe(map((data) => {
      return data;
    })) ;
  }
  getStatiAttivita(): Observable<ClassicType[]> {
    return this.http.get<ClassicType[]>(
      `${this.apiPaths.getBaseAPI()}/task/attivita/stati`,
      this.options()).pipe(map((data) => {
      const output: ClassicType[] = [];
      data.forEach(item => output.push({id: item.id.toString(), label: item.label}))
      return output;
    }));
  }
  getTipiAttivita(): Observable<ClassicType[]> {
    return this.http.get<ClassicType[]>(
      `${this.apiPaths.getBaseAPI()}/task/attivita/types`,
      this.options()).pipe(map((data) => {
      return data;
    }));
  }
  getAttivita(value): Observable<RitornoPaginazione<Intervento[]>> {
    return this.http.post<RitornoPaginazione<Intervento[]>>(
      `${this.apiPaths.getBaseAPI()}/rma/interventi/list`,
      JSON.stringify(value),
      this.options()).pipe(map((data) => {
      return data;
    }));
  }

  getInfoAttivita(value): Observable<any> {
    return this.http.post<any>(`${this.apiPaths.getBaseAPI()}/rma/interventi/get`,
      JSON.stringify(value),
      this.options()).pipe(map((data) => {
      return data;
    }));
  }
  addAttivita(value): Observable<any> {
    return this.http.post<any>(`${this.apiPaths.getBaseAPI()}/rma/interventi/add`,
      JSON.stringify(value),
      this.options()).pipe();
  }
  updateAttivita(value): Observable<any> {
    return this.http.post<any>(`${this.apiPaths.getBaseAPI()}/rma/interventi/update`,
      JSON.stringify(value),
      this.options()).pipe();
  }
  deleteAttivita(value): Observable<any> {
    return this.http.post<any>(`${this.apiPaths.getBaseAPI()}/rma/interventi/delete`,
      JSON.stringify(value),
      this.options()).pipe();
  }
  LeggiEmailAttivita(): Observable<any> {
    return this.http.get<any>(`${this.apiPaths.getBaseAPI()}/rma/rma/mail`,
      this.options()).pipe();
  }
  getAttivitaTecnici(inizio?: string,fine?: string, tecnici?: number[]): Observable<InterventiTecnico[]> {
    return this.http.post<any>(
      `${this.apiPaths.getBaseAPI()}/rma/interventi/day`,
      JSON.stringify({inizio:inizio, fine:fine, tecnici: tecnici}),
      this.options()).pipe(map((data) => {
      return data;
    }));
  }
  getGanttAttivitaTecnici(inizio?: string,fine?: string, tecnici?: number[]): Observable<any> {
    return this.http.post<any>(
      `${this.apiPaths.getBaseAPI()}/rma/interventi/gantt`,
      JSON.stringify({inizio:inizio, fine:fine, tecnici: tecnici}),
      this.options()).pipe(map((data) => {
      return data;
    }));
  }
  getProveAcquistoCliente(filter): Observable<RitornoPaginazione<ProvaAcquisto[]>> {
    return this.http.post<RitornoPaginazione<ProvaAcquisto[]>>(
      `${this.apiPaths.getBaseAPI()}/rma/register/cliente`,
      JSON.stringify(filter),
      this.options()).pipe(map((data) => {
      return data;
    }));
  }
}
