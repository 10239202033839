import { DatePipe } from '@angular/common';
import { ThisReceiver } from '@angular/compiler/src/expression_parser/ast';
import { AfterViewChecked, AfterViewInit, Component, ComponentFactoryResolver, Injector, Input, OnChanges, OnInit, SimpleChanges, ViewChild, ViewContainerRef} from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ApiPathsService } from 'src/app/api-paths.service';
import { LISTComponent } from 'src/app/task/list/list.component';
import { NewsComponent } from 'src/app/task/news/news.component';
import { environment } from 'src/environments/environment';


@Component({
  selector: 'app-check-per-data',
  templateUrl: './check-per-data.component.html',
  styleUrls: ['./check-per-data.component.scss']
  
})
export class CheckPerDataComponent implements OnInit, AfterViewInit, OnChanges {
  @ViewChild('dynamicComponentContainer', { read: ViewContainerRef }) container: ViewContainerRef;
  storico = false;
  filtro: any = {};
  dati = {
    date: ['2024-05-24', '2024-05-25'],
    data: [
    {
      label: "testo check ptr",
      values: [{value: 0, esito:'OK' },{value: 2, esito:'OK' }] // nb 1 oggetto dell'array = 1 data
    }
  ]}
  @Input() cliente: number;
  @Input() clienteObject: any;
  environment = environment;
  result : any;
  TasklistLoaded: boolean = false;
  constructor(private ApiPaths: ApiPathsService, private datepipe: DatePipe, private modalService:NgbModal, private injector: Injector, private resolver: ComponentFactoryResolver){}
  ngOnInit(): void {
    this.filtro.inizio = this.datepipe.transform(new Date(), 'yyyy-MM-dd');
    this.filtro.fine = this.datepipe.transform(new Date(), 'yyyy-MM-dd');
    this.Refresh();
    
    
  }
  ngOnChanges(changes: SimpleChanges): void {
    console.log("changed",this.container && !this.TasklistLoaded, this.container, this.TasklistLoaded )
    if(changes && changes['storico']?.currentValue != changes['storico']?.previousValue)
      this.loadTaskList();
  }

  loadTaskList(){
    console.log("load",this.container != undefined && !this.TasklistLoaded, this.container, this.TasklistLoaded )
    if(this.container && !this.TasklistLoaded){
      const factory = this.resolver.resolveComponentFactory(LISTComponent);
      this.container.clear();
      var componentRef =  this.container.createComponent(factory);
      componentRef.instance.type = 'V'
      componentRef.instance.cliente = this.cliente;
      componentRef.instance.clienteObject = this.clienteObject;
      componentRef.instance.hideAddButton = true;
      componentRef.instance.filter = '0';
      this.TasklistLoaded = true;
    }
  }

  ngAfterViewInit(): void {
   
  }

  switchStorico(){
    this.storico = !this.storico;
    this.loadTaskList();
  }

  Refresh(){
    this.ApiPaths.ClassicPost('/task/valutazioni/day',this.filtro).subscribe(
      (data)=>{
        this.dati = data;
      }
    )
  }
  add(){
    this.addDynamicButton(this.environment.TaskAddon['DynamicFields']?.find(x=>x.type == 'V'))
  }
  addDynamicButton(dynamicField) {
    const editmodal = this.modalService.open(NewsComponent, {
      centered: true,
      backdrop: 'static',
      size: 'xl',
    });
    (<NewsComponent>editmodal.componentInstance).DynamicFields = dynamicField;
    (<NewsComponent>editmodal.componentInstance).IsModal = true;
    (<NewsComponent>editmodal.componentInstance).Item = {cliente: this.cliente, clienteObject: this.clienteObject, checks:[]};
    (<NewsComponent>editmodal.componentInstance).updated.subscribe(data => {
      this.Refresh();
    });
  }
  

}
