<div class="modal-header"  *ngIf="IsModal" >
  <h5 class="modal-title" id="editUserLabel">Task</h5>
  <button type="button" class="close" (click)="save()" aria-label="Save">
    <span aria-hidden="true">Salva</span>
  </button>
  <button type="button" class="close" (click)="close()" aria-label="Close">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div [ngClass]="{'': IsModal, 'card': !IsModal }" *ngIf="!CampiAggiuntivi || environment.RmaAddOn['campiSum']">
  <div [ngClass]="{'modal-body': IsModal, 'card-body': !IsModal }">
    <div class="row">
    <div class="col-md-4 ">
      <div class="form-group">
        <label for="nome" class="control-label col-form-label">Titolo:</label>
        <input type="text" #nome="ngModel" required [readOnly]="Item?.id" [(ngModel)]="Item.nome" class="form-control" id="nome" placeholder="Titolo">
        <small class="form-text text-danger" *ngIf="!nome.valid && (nome.dirty || nome.touched)">Campo richiesto!</small>
      </div>
    </div>
      <div class="col-lg-4">
        <label for="manager" class="control-label col-form-label">Cliente:</label>
        <ng-multiselect-dropdown [settings]="clientidropdownSettings" [placeholder]="'Scadenza.cliente' | translate" id="cliente" [data]="clienti"
                                 [(ngModel)]="clientiselezionati" (onFilterChange)="filtroCliente($event)" (ngModelChange)="filtroProdotti('')" >
        </ng-multiselect-dropdown>
      </div>
      <div class="col-lg-4">
        <label for="manager" class="control-label col-form-label">Prodotto</label>
        <ng-multiselect-dropdown [settings]="prodottidropdownSettings" [placeholder]="'Scadenza.prodotto' | translate" id="prodotto" [data]="prodotti"
                                 [(ngModel)]="prodottiselezionati" (onFilterChange)="filtroProdotti($event)" >
        </ng-multiselect-dropdown>
      </div>

    <div class="col-3">
      <div class="form-group">
        <label for="data_inizio_prevista" class="control-label col-form-label">Data Task:</label>
        <input type="datetime-local" [(ngModel)]="Item.data_inizio_prevista" class="form-control" id="data_inizio_prevista">
      </div>
    </div>
    <div class="col-3">
      <div class="form-group">
        <label for="data_fine_prevista" class="control-label col-form-label">{{'Data Scadenza' | translate}}:</label>
        <input type="datetime-local" [(ngModel)]="Item.data_fine_prevista" class="form-control" id="data_fine_prevista">
      </div>
    </div>

    <div class="col-lg-3">
      <label for="manager" class="control-label col-form-label">{{'Scadenza.manager' | translate}}: *</label>
      <ng-multiselect-dropdown [settings]="utentidropdownSettings" [placeholder]="'Scadenza.manager' | translate" id="manager" [data]="managers"
                               [(ngModel)]="managerselezionati" (onFilterChange)="filtroResponsabile($event)" >
      </ng-multiselect-dropdown>
    </div>
    <div class="col-md-2 " *ngIf="Item?.id">
      <div class="form-group">
        <label for="closed" class="control-label col-form-label">Concluso:</label>
        <input type="checkbox" #nome="ngModel" [readOnly]="!permission.isPermitted('gestione-attivita')"  [(ngModel)]="Item.chiuso" class="form-control" id="closed">
      </div>
    </div>

      <div class="col-md-12 "></div>
      <div class="col-3">
        <div class="form-group">
          <label for="data_inizio_effettiva" class="control-label col-form-label">{{'Scadenza.dataie' | translate}}:</label>
          <input type="datetime-local" [(ngModel)]="Item.data_inizio_effettiva" class="form-control" id="data_inizio_effettiva">
        </div>
      </div>
      <div class="col-3">
        <div class="form-group">
          <label for="data_fine_effettiva" class="control-label col-form-label">{{'Scadenza.datafe' | translate}}:</label>
          <input type="datetime-local" [(ngModel)]="Item.data_fine_effettiva" class="form-control" id="data_fine_effettiva">
        </div>
      </div>
      <div class="col-md-12 ">
        <div class="form-group">
          <label for="desc" class="control-label col-form-label">Descrizione:</label>
          <textarea type="text" #desc="ngModel" required [(ngModel)]="Item.note" [readOnly]="Item?.id" class="form-control" id="desc" placeholder="Descrizione"></textarea>
          <small class="form-text text-danger" *ngIf="!desc.valid && (desc.dirty || desc.touched)">Campo richiesto!</small>
        </div>
      </div>
      <div class="col-md-12 ">
        <div class="form-group">
          <label for="desc" class="control-label col-form-label">Note:</label>
          <textarea type="text" #desc="ngModel"  [(ngModel)]="Item.noteTecnico" rows="10"  class="form-control" id="notetec" placeholder="Note"></textarea>
        </div>
      </div>
      <div class="col-md-12 " *ngIf="environment.RmaAddOn['campiSum']">
        <app-field-viewer [(DropdownSources)]="DSource" (OnChange)="ChangeHandler($event)" [(Campi)]="CampiAggiuntivi" [(Oggetto)]="Item" ></app-field-viewer>
      </div>
    </div>
  </div>




</div>
<div class="card"  *ngIf="!CampiAggiuntivi || environment.RmaAddOn['hasCards']">
  <ul ngbNav #nav="ngbNav" activeId="attivita" class="nav-tabs">
    <li ngbNavItem="todo" >
      <a ngbNavLink> {{'Todo'|translate}} </a>
      <ng-template ngbNavContent>
        <div *ngIf="environment.TaskAddon.checkList">
          <app-check-list [(Items)]="Item.todo"></app-check-list>
        </div>
      </ng-template>
    </li>
    <li ngbNavItem="file" >
      <a ngbNavLink> {{'File'|translate}} </a>
      <ng-template ngbNavContent>
        <div *ngIf="environment.TaskAddon.checkList">
          <div *ngIf="Item?.id" class="card-body">
            <app-file-uploader [url]="'/rma/interventi/file'" [(id)]="Item.id" [ListaFile]="true" (onChange)="onFileChanged()"></app-file-uploader>
          </div>
        </div>
      </ng-template>
    </li>
    <li ngbNavItem="Partecipanti" >
      <a ngbNavLink> Partecipanti </a>
      <ng-template ngbNavContent>
        <app-partecipanti-list class="col-12" [rest]="false" [List]="Item.partecipantiObject" [hasAddButton]="true"  [Padre]="Item" ></app-partecipanti-list>
      </ng-template>
    </li>
    <li ngbNavItem="Tempi">
      <a ngbNavLink> Tempi </a>
      <ng-template ngbNavContent>
        <app-Shered-tempi-list class="col-12" [List]="Item.tempi" (onChange)="this.refresh()" [hasAddButton]="permission.isPermitted('gestione-segnalazione') " [url]="'/rma/tempi'" [ModificheAbilitate]="true" [Padre]="Item" ></app-Shered-tempi-list>
      </ng-template>
    </li>

    <li ngbNavItem="attivita"  *ngIf="Item?.id && permission.isPermitted('gestione-segnalazione') && !permission.isOperativeRoles(['C','AC']) && environment.RmaAddOn.sottoTask">
      <a ngbNavLink> {{'RMA.attivita.title'|translate}} </a>
      <ng-template ngbNavContent>
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-lg-3">
                <input [(ngModel)]="TitoloScadenza" placeholder="Titolo" type="text" name="voce" (keyup.enter)="focusDescr()" class="form-control">
              </div>
              <div class="col-lg-4">
                <textarea [(ngModel)]="DescrizioneScadenza" #descr placeholder="Descrizione" name="descrizione" type="text" (keyup.enter)="AggiungiScadenzaInline()" class="form-control"></textarea>
              </div>
              <div class="col-lg-3">
                <ng-multiselect-dropdown [settings]="utentidropdownSettings" placeholder="Responsabile" id="managerScadenza" [data]="managers"
                                         [(ngModel)]="segnalazionemanagerselezionati" (onFilterChange)="filtroResponsabile($event)" >
                </ng-multiselect-dropdown>
              </div>
              <div class=" mt-3 mt-md-0 p-1">
                <button class="btn btn-outline-info rounded-pill" style="width: 100%;" (click)="AggiungiScadenzaInline()" type="button">Aggiungi</button>
              </div>
            </div>
          </div>
        </div>
        <app-attivita-rma [(ListaScadenze)]="Item.figli" [hideAdd]="true"  (AddButtonClick)="ScadAddButton()" (Updated)="refresh()"></app-attivita-rma>
      </ng-template>
    </li>
  </ul>
  <div [ngbNavOutlet]="nav" class="mt-2"></div>
</div>
<div [ngClass]="{'': IsModal, 'card': !IsModal }" *ngIf="CampiAggiuntivi && Item && !environment.RmaAddOn['campiSum']">
  <app-sector-viewer [(DropdownSources)]="DSource" (OnChange)="ChangeHandler($event)" [(Campi)]="CampiAggiuntivi" [(Oggetto)]="Item"></app-sector-viewer>
</div>
<div class="d-md-flex align-items-center justify-content-end m-b-10 m-r-10">
  <app-stampa [Stampe]="Stampe" *ngIf="Stampe && Stampe.length > 0"></app-stampa>
  <button type="submit" (click)="save()" style="margin: 0.75px;" class=" btn btn-success  waves-effect waves-light">
    <span class="mr-1 text-white "> Salva </span>
    <i class="far fa-save text-white"></i>
  </button>
  <button type="submit" (click)="save(true)" style="margin: 0.75px;" class=" btn btn-success  waves-effect waves-light">
    <span class="mr-1 text-white "> Salva e chiudi </span>
    <i class="far fa-save text-white"></i>
  </button>
  <button type="submit" (click)="close()"  style="margin: 0.75px;" class=" btn btn-danger  waves-effect waves-light">
    <span class="mr-1 text-white "> Chiudi </span>
    <i class="far fa-window-close text-white"></i>
  </button>
</div>
