import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {RicercaPaginazione} from "../../Admin/models/sendpersonale";
import {PersonaleStoreService} from "../../Admin/_service/personale-store.service";
import {IDropdownSettings} from "ng-multiselect-dropdown";
import {Subscription} from "rxjs";
import {Persona} from "../../Admin/models/persona";
import {Alunno} from "../_Models/alunno";
import {FileStoreService} from "../../shared/_services/file-store.service";
import {ActivatedRoute, Router} from "@angular/router";
import {ApiRestService} from "../_services/api-rest.service";
import {Edizione} from "../_Models/edizione";
import {FormazioneStoreService} from "../_services/formazione-store.service";
import {EditPersonaComponent} from "../../Admin/edit-persona/edit-persona.component";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {MultimediaCourse} from "../_Models/multimedia-course";
import {ApiPathsService} from "../../api-paths.service";
import {Azienda} from "../../Admin/models/Azienda";
import {ClassicType} from "../../shared/models/ClassicType";
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-alunni-list',
  templateUrl: './alunni-list.component.html'
})
export class AlunniListComponent implements OnInit {
  UserDropdownSettings: IDropdownSettings = {
    singleSelection: false,
    closeDropDownOnSelection: true,
    idField: 'id',
    textField: 'fullName',
    allowRemoteDataSearch:true,
    allowSearchFilter: true,
    searchPlaceholderText: "inserire almeno 3 caratteri",
  };
  DropdownSettings: IDropdownSettings = {
    singleSelection: true,
    closeDropDownOnSelection: true,
    idField: 'id',
    textField: 'label',
    allowRemoteDataSearch:true,
    allowSearchFilter: true,
    searchPlaceholderText: "inserire almeno 3 caratteri",
  };
  AziendeDropdownSettings: IDropdownSettings = {
    singleSelection: true,
    closeDropDownOnSelection: true,
    idField: 'id',
    textField: 'nominativo',
    allowRemoteDataSearch:true,
    allowSearchFilter: true,
    searchPlaceholderText: "inserire almeno 3 caratteri",
  };
  subscriptions: Subscription = new Subscription();
  @Input() HasAdd: boolean = true;
  @Input() isModal: boolean = false;
  @Input() Alunni: Alunno[] = [];
  @Output() AlunniChange = new EventEmitter<Alunno[]>();
  @Input() Edizione: Edizione;
  @Output() EdizioneChange = new EventEmitter<Edizione>();
  @Input() MediaCourse: MultimediaCourse;
  @Output() MediaCourseChange = new EventEmitter<MultimediaCourse>();
  @Output() onChange = new EventEmitter();
  Persone: Persona[] = [];
  Aziende: Azienda[] = [];
  Dipendenti: Persona[] = [];
  Reparti: ClassicType[] = [];
  aziendeSelected: Azienda[] = [];
  dipendentiSelected: Persona[] = [];
  repartiSelected: ClassicType[] = [];

  environment=environment;
  personeSelezionate: Persona[] = [];
  @Input() Modifica: boolean = true;
  constructor(
    private personaleStore: PersonaleStoreService,
    private filestore: FileStoreService,
    private Store: FormazioneStoreService,
    private apiRestService: ApiRestService,
    private router: Router,
    private route: ActivatedRoute,
    private modalService: NgbModal,
    private apiPath: ApiPathsService,
  ) { }

  ngOnInit(): void {
    this.subscriptions.add(this.personaleStore.$listaPersonale.subscribe(x => {
      if (x && x.data && x.data.length > 0)
        this.Persone = x.data
    }));
    this.apiPath.ClassicPost('/anagrafica/clienti/list', {page: 0, size: 15, ruolo: 'C'}).subscribe(x=>{
      this.Aziende = x.data;
    });
    if(this.environment.ClientiAddOn['repartoReferente']){
      this.apiPath.ClassicGet('/anagrafica/clienti/referenti/reparti').subscribe(data=>{
        this.Reparti = data;
      });
    }
  }
  AddUser(){
    if(this.dipendentiSelected?.length>0 )
      this.dipendentiSelected.forEach(dip=>{
        this.AddUserTolist(dip);
      })
    else if(this.personeSelezionate?.length > 0)
      this.personeSelezionate.forEach(pers=>{
        this.AddUserTolist(pers);
      })
  }
  AddUserTolist(Utente: Persona){
    if(this.Edizione)
      this.apiRestService.AddStudenteEdizione({id_edizione: this.Edizione.id, id_persona: Utente.id}).subscribe(data=>{
        this.Store.updateEdizione(this.Edizione);
        //this.personeSelezionate = [];
        this.onChange.emit();
      });
    else if(this.MediaCourse)
      this.apiRestService.AddStudenteMediaCorso({id_corso: this.MediaCourse.id, id_persona: Utente.id}).subscribe(data=>{
        this.onChange.emit();
        //this.personeSelezionate = [];
      });
  }
  AddNewUser(){
    this.personaleStore.updateEditable(false);
    this.personaleStore.updateShowAddForm(true);
    this.personaleStore.updateSelectedUser(new Persona());
    const editmodal = this.modalService.open(EditPersonaComponent, {
      centered: true,
      backdrop: 'static',
      size: 'xl',
    });
    (<EditPersonaComponent>editmodal.componentInstance).IsModal = true;
    (<EditPersonaComponent>editmodal.componentInstance).IDRuolo = "STU";
    (<EditPersonaComponent>editmodal.componentInstance).added.subscribe(data=>{
      this.AddUserTolist(data);
    })
  }
  filtroPersone(item: string, lenght: boolean) {
    if (item.length >= 3 )
      this.personaleStore.getListaPersonale(new RicercaPaginazione(item, 0, 1,"STU"));
    else if (lenght)
        this.personaleStore.getListaPersonale(new RicercaPaginazione(item, 1, 10, "STU"));
  }
  editContact(contact: Persona) {
    this.filestore.SelectSection('Personale');
    this.personaleStore.updateEditable(true);
    this.personaleStore.updateShowAddForm(false);
    this.personaleStore.updateSelectedUser(contact);
    if (this.isModal)
      this.modalService.dismissAll();
    this.router.navigate([this.route.snapshot.queryParams[' listautenti/dipendente '] ||'listautenti/dipendente',])
  }
  deleteContact(contact: Persona) {
    if(confirm("Sicuro di voler eliminare questo elemento dalla lista?"))
      if(this.Edizione)
        this.apiRestService.DeleteStudenteEdizione({id_edizione: this.Edizione.id, id_persona: contact.id}).subscribe(() => {
          this.Store.updateEdizione(this.Edizione);
          this.onChange.emit();
        });
      else if(this.MediaCourse)
        this.apiRestService.DeleteStudenteMediaCorso({id_corso: this.MediaCourse.id, id_persona: contact.id}).subscribe(() => {
          this.onChange.emit();
        });
  }

  SelectAzienda() {
    if(this.aziendeSelected?.length>0)
    this.apiPath.ClassicPost('/anagrafica/clienti/get', {id: this.aziendeSelected[0]?.id}).subscribe(x=>{
      this.Dipendenti = x?.referenti.map(x=> {
        x.fullName = x.nome + " " + x.cognome;
          return x;
      });
    });
  }

  SelectReparto() {
    this.apiPath.ClassicPost('/anagrafica/clienti/get', {id: this.aziendeSelected[0]?.id}).subscribe(x=>{
      this.Dipendenti = x?.referenti.map(x=> {
        x.fullName = x.nome + " " + x.cognome;
        return x;
      }).filter(x=> this.repartiSelected?.length>0 ?x.reparto == this.repartiSelected[0]?.id : true);
    });
  }

  GetAvanzamento(persona):{finished: boolean, percent: number, started: boolean}{
    if(persona.multimediaObject?.length == 0)
      return {finished: false, percent: 0, started: false};

    var out = {finished: persona.multimediaObject?.every(x=>x?.avanzamento?.chiuso), percent: 0, started:  persona.multimediaObject?.some(x=>x?.avanzamento != 0 && x?.avanzamento?.avanzamento > 0)};
    return out;
  }
}
