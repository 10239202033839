import {DatePipe} from "@angular/common";
import {Paginazione} from "../../shared/models/paginazione";

export class Filtri extends Paginazione{
  anno?: number;
  mese?: number;
  chiuso?: number;
  datepipe = new DatePipe('it-it');
  inizio?: string;
  fine?: string;
  periodo?: boolean;
  tutti?: boolean
  tipo?: any;
  chiuse?: boolean;
  persona?: number;
  rma?: any;
  cerca?: string;
  stato?: any;
  cliente?: number;
  hideCliente?: boolean;

  constructor(page?: number, size?: number, stato?: string, periodo?: boolean, inizio?: string | Date, fine?: string | Date, anno?:number, mese?:number) {
    super(page ?? 0, size ?? 10);
    const today = new Date();
    const inizioMese = new Date(today.getFullYear(), 0, 1);
    const fineMese = new Date(today.getFullYear(), 12, 0) ;
    this.inizio = this.datepipe.transform(inizio || inizioMese,'yyyy-MM-dd');
    this.fine = this.datepipe.transform(fine || fineMese, 'yyyy-MM-dd');
    this.periodo = periodo ?? true;
    this.anno = anno || today.getFullYear();
    this.mese = mese || today.getMonth()+1;
    this.tutti = false;
  }
}
