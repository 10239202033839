import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Permission} from "../../Login/_guards/Permission";
import {MultimediaCourse} from "../_Models/multimedia-course";
import {ServerPaginationService} from "../../shared/_services/server-pagination.service";
import {ActivatedRoute, Router} from "@angular/router";
import {ApiRestService as SharedApiRestService} from "../../shared/_services/api-rest.service";
import {ApiRestService } from "../_services/api-rest.service";
import {ClassicType} from "../../shared/models/ClassicType";
import {IDropdownSettings} from "ng-multiselect-dropdown";
import {FormazioneStoreService} from "../_services/formazione-store.service";
import {DownloadService} from "../../shared/_services/download.service";
import {ToastrService} from "ngx-toastr";
import {EventHandlerVars} from "@angular/compiler/src/compiler_util/expression_converter";
import {Incarico} from "../_Models/incarico";
import {Attivita} from "../_Models/attivita";
import {ApiPathsService} from "../../api-paths.service";

@Component({
  selector: 'app-media',
  templateUrl: './media.component.html',
  styleUrls: ['./media.component.css']
})
export class MediaComponent implements OnInit {
  classicTypeDropdownSettings: IDropdownSettings = {
    singleSelection: true,
    closeDropDownOnSelection: true,
    textField: 'label',
    idField: 'id',
  };
  DropdownSettings: IDropdownSettings = {
    singleSelection: true,
    closeDropDownOnSelection: true,
    textField: 'nome',
    idField: 'id',
  };
  @Input() Lista: MultimediaCourse[] = undefined;
  @Output() ListaChange = new EventEmitter<MultimediaCourse[]>();
  @Input() lezione: Attivita = undefined;
  @Input() HideSoglia: boolean = true;
  @Output() onRefresh = new EventEmitter<any>();
  @Input() Editabile: boolean = true;
  Types: ClassicType[] = [];
  SelectedTypes: ClassicType[] = [];
  cat_id: string;
  page = 1;
  pageSize: number = 50;
  pagesizeserver = 4;
  newpagegenerated: number;
  @Input() CorsiAggiungibili: MultimediaCourse[] = undefined;
  SelectedCourse : MultimediaCourse[] = [];
  @Output() onOpenCorso = new EventEmitter<any>();
  constructor(
    public permission: Permission,
    private paginationService: ServerPaginationService,
    private route: ActivatedRoute,
    public router: Router,
    private apiRest: ApiRestService,
    private apiPaths: ApiPathsService,
    private store: FormazioneStoreService,
    private sharedApiRest: SharedApiRestService,
    private downloadService: DownloadService,
    private toast: ToastrService,
    ) { }

  ngOnInit(): void {
    if(!this.lezione){
      this.route.params.subscribe(data => {
        this.cat_id = data['type'];
        this.refreshTable(1);
      })
      this.refreshTable(1);
    }
  }
  download(file: MultimediaCourse) {
    this.sharedApiRest.downloadFle(file.allegato).subscribe(data => {
      this.downloadService.downloadFile(data)
    });
  }
  edit(value: MultimediaCourse) {
    this.router.navigate([this.route.snapshot.queryParams['formazione/media/edit/'+ value.id.toString()] || 'formazione/media/edit/' + value.id.toString(),]).then();
  }
  add(){
    if(this.SelectedCourse?.length > 0) {
      if(this.Lista?.find(x=> x.id == this.SelectedCourse[0].id)){
        this.toast.warning("Questo Corso é gia stato inserito")
        return;
      }
      if(this.CorsiAggiungibili?.find(x=> x.id == this.SelectedCourse[0].id) == undefined){
        this.toast.warning("Corso non trovato")
        return;
      }
      this.Lista?.push(this.CorsiAggiungibili.find(x=> x.id == this.SelectedCourse[0].id))
      this.SelectedCourse = [];
      this.ListaChange.emit(this.Lista)
      return
    }
      this.router.navigate([this.route.snapshot.queryParams['formazione/media/edit'] || 'formazione/media/edit',]);
  }
  getPage(page: number) {
    this.page = this.paginationService.paginadellalista(page, this.pagesizeserver);
    const pagetosend = this.paginationService.getPage(page, this.newpagegenerated, this.pagesizeserver);
    if ((pagetosend - 1) % this.pagesizeserver === 0) {
      this.newpagegenerated = page;
      this.refreshTable(pagetosend);
    }
  }
  refreshTable(page) {
    this.apiRest.getMediaTypes().subscribe(data=>this.Types = data);
    const filter = {page: (page - 1) / this.pagesizeserver +1, size: this.pagesizeserver * this.pageSize, cat_id: parseInt(this.cat_id), tipo: undefined}
    if(this.SelectedTypes && this.SelectedTypes[0])
      filter.tipo = this.SelectedTypes[0].id
    this.apiRest.getMediaList(filter).subscribe(data => this.Lista = data.data)
  }

  open(item: MultimediaCourse) {
    var type = item.tipoObject.id.toString().toUpperCase();
    var _url = 'formazione/' + (type == "V" ? 'video' : (type == "P" ? 'pdf': (type == "Q" ? 'questionario' : '') )) + '/' + item.id.toString() + (this.lezione ? '/'+ this.lezione.id : '')
    console.log(_url);
    this.router.navigate( [this.route.snapshot.queryParams[_url] || _url,]).then();
    this.onOpenCorso?.emit();
  }

  delete(item: MultimediaCourse) {
    if(!confirm("Sei sicuro di voler eliminare questo elemento?")) return;
    if(!this.lezione){
      this.apiPaths.ClassicPost('/formazione/corsi/delete', {id: item.id}).subscribe(data=>{
        this.refreshTable(1);
        this.onRefresh.emit();
      })
    }else{
      this.Lista?.splice(this.Lista?.findIndex(x=>x == item),1);
      this.ListaChange.emit(this.Lista)

    }
  }
}
