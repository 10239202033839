<div class="dropdown-container">
    <div class="dropdown" (click)="toggleDropdown()">
      Frequenza Personalizzata
      <span class="dropdown-icon" [ngClass]="{'rotate': isDropdownOpen}">&#9660;</span>
    </div>
    <div class="dropdown-menu" [ngClass]="{'show': isDropdownOpen}">
      <div class="dropdown-content" *ngIf="ripetizione">
        <div class="dropdown-body">
            <div class="form-group">
              <div class="form-row">
                <div class="form-field">
                  <label for="repeatEvery">Ripeti ogni</label>
                  <input id="repeatEvery" type="number" [(ngModel)]="ripetizione.repeatEvery"  name="repeatEvery" class="form-input" min="1" (ngModelChange)="verify('repeatEvery')">
                </div>
                <div class="form-field">
                  <label for="frequency">Unità</label>
                  <select id="frequency" [(ngModel)]="ripetizione.frequency" name="frequency" class="form-input" required>
                    <option value="days">Giorni</option>
                    <option value="weeks">Settimane</option>
                    <option value="months">Mesi</option>
                    <option value="years">Anni</option>
                  </select>
                </div>
                <div *ngIf='showOrder' class="form-field">
                    <label for="frequency">Ordine</label>
                    <select id="frequency" [(ngModel)]="ripetizione.ordine"  name="ordine" class="form-input" required>
                      <option value="first">Primo</option>
                      <option value="second">Secondo</option>
                      <option value="third">Terzo</option>
                      <option value="fouth">Quarto</option>
                    </select>
                  </div>
              </div>
            </div>
  
            <div class="form-group">
              <label>Si ripete il</label>
              <div class="day-selection">
                <div class="day-circle" [class.selected]="ripetizione.daysOfWeek?.monday" (click)="toggleDay('monday')">L</div>
                <div class="day-circle" [class.selected]="ripetizione.daysOfWeek?.tuesday" (click)="toggleDay('tuesday')">M</div>
                <div class="day-circle" [class.selected]="ripetizione.daysOfWeek?.wednesday" (click)="toggleDay('wednesday')">M</div>
                <div class="day-circle" [class.selected]="ripetizione.daysOfWeek?.thursday" (click)="toggleDay('thursday')">G</div>
                <div class="day-circle" [class.selected]="ripetizione.daysOfWeek?.friday" (click)="toggleDay('friday')">V</div>
                <div class="day-circle" [class.selected]="ripetizione.daysOfWeek?.saturday" (click)="toggleDay('saturday')">S</div>
                <div class="day-circle" [class.selected]="ripetizione.daysOfWeek?.sunday" (click)="toggleDay('sunday')">D</div>
              </div>
            </div>
  
            <div class="form-group">
                <label>Fine</label>
              <div class="radio-row">
                <div class="form-field">
                  <label><input type="radio" [(ngModel)]="ripetizione.endCondition" name="endCondition" value="never" class="form-input"> Mai</label>
                </div>
                <div class="form-field">
                  <label><input type="radio" [(ngModel)]="ripetizione.endCondition" name="endCondition" value="date" class="form-input"> Data</label>
                </div>
                <div class="form-field">
                  <label><input type="radio" [(ngModel)]="ripetizione.endCondition" name="endCondition" value="occurrences" class="form-input"> Dopo</label>
                </div>
              </div>
            </div>
  
            <div class="form-group" *ngIf="ripetizione.endCondition === 'date'">
              <label for="endDate">Data</label>
              <input id="endDate" type="date" [(ngModel)]="ripetizione.endDate" name="endDate" class="form-input">
            </div>
  
            <div class="form-group" *ngIf="ripetizione.endCondition === 'occurrences'">
              <label for="occurrences">Occorrenze</label>
              <input id="occurrences" type="number" [(ngModel)]="ripetizione.occurrences" name="occurrences" class="form-input"(ngModelChange)="verify('occurrences',2)">
            </div>
        </div>
      </div>
    </div>
  </div>
  